<template>
  <ValidationProvider tag="div" class="single-answer column is-one-third" :rules="validationRules" v-slot="{ errors }">
    <div class="question-title">
      <span v-if="showQuestionCode">{{ question.code }}: </span><span v-html="question.question[lang]"></span>
      <p v-if="errors[0]" class="help is-danger">{{ errors[0] }}</p>
    </div>
    <div class="card">
      <div class="card-content">
        <b-field v-for="locale in survey.supported_languages" :key="locale">
          <b-radio-button
              :value="value[`${question.qid}`]"
              @input="onInput"
              :native-value="locale"
              :disabled="loading"
              size="is-medium"
          >
            <div class="is-flex is-vcentered" style="min-width: 150px;">
              <div class="icon is-medium mr-5">
                <img :src="`/flags/${ getCountryCode(locale) }.svg`" alt=""/>
              </div>
              <div>{{ getLanguageNativeName(locale) }}</div>
            </div>
          </b-radio-button>
        </b-field>
      </div>
    </div>
  </ValidationProvider>
</template>
<script>

import { QuestionMixin } from '@/mixins/question'
import LocaleCode from 'locale-code'
import { SurveyMappingMixin } from '@/mixins/survey-mapping'

const getCountryCode = LocaleCode.getCountryCode
const getLanguageNativeName = LocaleCode.getLanguageNativeName

const LOCALE_MAP = {
  en: 'en-US',
  th: 'th-TH',
  'zh-TW': 'zh-TW',
  'zh-CN': 'zh-CN',
  vi: 'vi-VN',
  ko: 'ko-KR',
  ja: 'ja-JP',
}

export default {
  name: 'SelectLanguage',
  mixins: [SurveyMappingMixin, QuestionMixin],
  methods: {
    getCountryCode (locale) {
      if (!LOCALE_MAP[locale]) return locale
      return getCountryCode(LOCALE_MAP[locale]).toLowerCase()
    },
    getLanguageNativeName (locale) {
      if (!LOCALE_MAP[locale]) return locale
      return getLanguageNativeName(LOCALE_MAP[locale])
    },
    onInput (locale) {
      this.$emit('input', { [`${this.question.qid}`]: locale })
      if (locale !== this.$route.query?.lang) {
        this.$router.replace({ query: { ...this.$route.query, lang: locale } })
      }
      this.$i18n.locale = locale
    }
  }
}
</script>
